/* eslint-disable react/no-danger */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useRef, useState } from "react";
import { PropTypes } from "prop-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import { DocumentContext } from "~context/DocumentContext";
import Footer from "~components/Footer";
import Layout from "~components/Layout";
import SEO from "~components/SEO";

const AboutPage = ({ data, location }) => {
  const [verticalPadding, setVerticalPadding] = useState(null);
  const documentContext = useContext(DocumentContext);
  const headingRef = useRef();
  const { windowHeight } = documentContext;
  const { frontmatter } = data.markdownRemark;

  useEffect(() => {
    if (!headingRef.current) {
      return;
    }

    const { height } = headingRef.current.getBoundingClientRect();
    const adjustedHeadingSize = height / 2;
    const remainingVerticalSpace = windowHeight / 2 - adjustedHeadingSize;

    setVerticalPadding(
      `${(remainingVerticalSpace / documentContext.windowHeight) * 100}vh`
    );
  }, [headingRef.current, windowHeight]);

  //

  return (
    <>
      <SEO
        customTitle={frontmatter.title}
        customDescription={frontmatter.seoDescription}
        customKeywords={frontmatter.seoKeywords}
        path={location.pathname}
      />

      <Layout className="about-page w-full relative bg-slate">
        <section
          className="w-full flex items-center justify-center sm:pb-48"
          style={{
            paddingTop: verticalPadding,
            paddingBottom: verticalPadding
          }}
        >
          <article className="grid relative text-off-white">
            <div
              ref={headingRef}
              className="about-page__heading animation-appear animation-delay-2 grid-end-6 md:grid-end-8 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative text-off-white mb-6 f1"
              dangerouslySetInnerHTML={{ __html: frontmatter.heading }}
            />

            <div
              className="about-page__content animation-appear animation-delay-3 grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative mb-6 text-off-white b2"
              dangerouslySetInnerHTML={{ __html: frontmatter.content }}
            />

            <div
              className="about-page__content animation-appear animation-delay-3 grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative text-off-white b2"
              dangerouslySetInnerHTML={{ __html: frontmatter.footnote }}
            />

            {frontmatter.sponsors && frontmatter.sponsors.length > 0 && (
              <div className="grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative">
                {frontmatter.sponsors.map(({ image }) => (
                  <>
                    {image?.childImageSharp?.fluid && (
                      <Img
                        className="w-full xs:w-3/4 relative block mt-4 mb-4"
                        fluid={image.childImageSharp.fluid}
                        alt="Sponsor"
                      />
                    )}
                  </>
                ))}
              </div>
            )}

            <div
              className="about-page__content animation-appear animation-delay-3 grid-end-3 md:grid-end-4 xs:grid-end-12 grid-start-4 md:grid-start-3 xs:grid-start-1 relative text-off-white b2"
              dangerouslySetInnerHTML={{ __html: frontmatter.contact }}
            />
          </article>
        </section>
      </Layout>

      <Footer />
    </>
  );
};

AboutPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.shape({})
    })
  }).isRequired,
  location: PropTypes.shape({}).isRequired
};

export default AboutPage;

export const query = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        heading
        content
        footnote
        sponsors {
          image {
            childImageSharp {
              fluid(maxWidth: 512) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
        contact
        seoDescription
        seoKeywords
      }
    }
  }
`;
